import createReactComponent from '../createReactComponent';
export default createReactComponent('unlink', 'IconUnlink', [
  [
    'path',
    {
      d: 'M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M16 21l0 -2', key: 'svg-2' }],
  ['path', { d: 'M19 16l2 0', key: 'svg-3' }],
  ['path', { d: 'M3 8l2 0', key: 'svg-4' }],
  ['path', { d: 'M8 3l0 2', key: 'svg-5' }],
]);
