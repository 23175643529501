import createReactComponent from '../createReactComponent';
export default createReactComponent('link', 'IconLink', [
  [
    'path',
    {
      d: 'M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5',
      key: 'svg-1',
    },
  ],
]);
